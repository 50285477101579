export const services = [
  {
    id: "73636",
    href: "/product-oriented-delivery/",
    category: "Development Stack",
    title: "Product-oriented delivery",
    description: "Achieve your business goals with flexible software delivery",
    tags: ["idea", "poc", "prototype", "mvp", "improve_app_maintainability", "optimise_and_future_proof"]
  },
  {
    id: "8798",
    href: "/mobile-app-development/",
    category: "Development Stack",
    title: "Mobile Development",
    description: "Create engaging mobile experiences that users love",
    tags: ["idea", "poc", "prototype", "mvp", "mobile_development"]
  },
  {
    id: "8799",
    href: "/web-development/",
    category: "Development Stack",
    title: "Web Development",
    description: "Build scalable web solutions that drive business growth",
    tags: ["web_development", "idea", "poc", "prototype"]
  },
  {
    id: "8802",
    href: "/ui-ux-design/",
    category: "Design Services",
    title: "UI/UX Design",
    description: "Create intuitive digital experiences that users love",
    tags: ["ui_ux_design", "idea", "poc", "prototype", "mvp"]
  },
  {
    id: "75532",
    href: "/ai-development-services/",
    category: "Development Stack",
    title: "AI Development",
    description: "Revolutionise your industry with AI-powered innovation and data-centric solutions",
    tags: ["idea", "poc", "prototype", "leverage_ai_and_automation", "manage_and_analyse_your_data"]
  },
  {
    id: "19596",
    href: "/data-science-services/",
    category: "Development Stack",
    title: "Data Science",
    description: "Deep-dive into your data and boost business performance",
    tags: ["manage_and_analyse_your_data", "build_consistent_data_strategy", "leverage_ai_and_automation"]
  },
  {
    id: "8800",
    href: "/cybersecurity/",
    category: "Cloud & DevOps",
    title: "Cybersecurity",
    description: "Build a proactive strategy to mitigate risk and maintain security even as you scale",
    tags: ["bolster_security_and_compliance", "manage_technical_issues", "optimise_and_future_proof"]
  },
  {
    id: "54956",
    href: "/devops/",
    category: "Cloud & DevOps",
    title: "DevOps Services",
    description: "Streamline your development and operations for faster delivery",
    tags: ["devops_services", "cicd_implementation", "cloud_infrastructure"]
  },
  {
    id: "8801",
    href: "/cloud-solutions/",
    category: "Cloud & DevOps",
    title: "Cloud Solutions",
    description: "Transform your business with scalable cloud infrastructure",
    tags: ["cloud_solutions", "cloud_infrastructure", "docker_kubernetes"]
  },
  {
    id: "9894",
    href: "/aws-cloud-services/",
    category: "Cloud & DevOps",
    title: "AWS Services",
    description: "Leverage AWS expertise for your cloud transformation",
    tags: ["cloud_infrastructure", "aws_expertise"]
  },
  {
    id: "54090",
    href: "/data-strategy/",
    category: "Development Stack",
    title: "Data Strategy",
    description: "Unlock the ultimate value of your data with end-to-end data strategy consulting",
    tags: ["build_consistent_data_strategy", "manage_and_analyse_your_data", "become_more_agile"]
  },
  {
    id: "54304",
    href: "/digital-transformation/",
    category: "Development Stack",
    title: "Digital Transformation",
    description: "Transform your business with innovative digital solutions",
    tags: ["digital_transformation", "optimise_and_future_proof"]
  },
  {
    id: "8803",
    href: "/legacy-modernization/",
    category: "Development Stack",
    title: "Legacy Modernization",
    description: "Modernize your legacy systems for improved performance",
    tags: ["legacy_system", "improve_app_maintainability"]
  },
  {
    id: "9804",
    href: "/software-testing/",
    category: "Development Stack",
    title: "Software Testing",
    description: "Ensure quality and reliability with comprehensive testing",
    tags: ["quality_assurance", "manage_technical_issues"]
  },
  {
    id: "75425",
    href: "/it-consulting/",
    category: "Cloud & DevOps",
    title: "IT Consulting",
    description: "Strategic technology consulting for business growth",
    tags: ["it_consulting", "optimise_and_future_proof"]
  },
  {
    id: "8806",
    href: "/business-analysis/",
    category: "Development Stack",
    title: "Business Analysis",
    description: "Transform business requirements into technical solutions",
    tags: ["business_analysis", "optimise_and_future_proof"]
  },
  {
    id: "8808",
    href: "/project-management/",
    category: "Development Stack",
    title: "Project Management",
    description: "Deliver projects on time and within budget",
    tags: ["project_management", "become_more_agile"]
  },
  {
    id: "8807",
    href: "/software-architecture/",
    category: "Development Stack",
    title: "Software Architecture",
    description: "Build robust and scalable software architectures",
    tags: ["software_architecture", "improve_app_maintainability"]
  }
];
