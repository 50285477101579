import mobileIcon from "../assets/icons/mobile.svg";
import webIcon from "../assets/icons/web.svg";
import designIcon from "../assets/icons/design.svg";
import devopsIcon from "../assets/icons/devops.svg";
import enterpriseIcon from "../assets/icons/entreprise.svg";
import cloudIcon from "../assets/icons/cloud.svg";

import fservice1_992 from "../assets/images/f1service_992.jpg";
import fservice1_219 from "../assets/images/fservice1_219.jpg";
import fservice1_747 from "../assets/images/fservice_747.jpg";
import fservice1_768 from "../assets/images/fservice_768.jpg";

import fservice2_992 from "../assets/images/fservice2_992.jpg";
import fservice3 from "../assets/images/fservice3.jpg";
import fservice4 from "../assets/images/fservice4.jpg";

import fservice5 from "../assets/images/fservice5.jpg";
import fservice6 from "../assets/images/fservice6.jpg";

export const industries = [
  {
    id: 1,
    title: "Mobile Development",
    url: "/services/mobile-development",
    image: {
      src: `${fservice1_992}`,
      srcSet: {
        full: `${fservice1_992} 992w`,
        small: `${fservice1_219} 219w`,
        medium: `${fservice1_747} 747w`,
        large: `${fservice1_768} 768w`,
      },
      alt: "mobile development",
    },
    icon: {
      src: mobileIcon,
      alt: "mobile development",
    },
    descriptions: [
      "Cross-platform mobile applications",
      "React Native & Flutter expertise",
    ],
  },
  {
    id: 2,
    title: "Web Development",
    url: "/services/web-development",
    image: {
      src: `${fservice2_992}`,
      srcSet: {
        full: `${fservice2_992} 992w`,
        small: `${fservice2_992} 219w`,
        medium: `${fservice2_992} 747w`,
        large: `${fservice2_992} 768w`,
      },
      alt: "web development",
    },
    icon: {
      src: webIcon,
      alt: "web development",
    },
    descriptions: [
      "Custom web applications & portals",
      "Interactive dashboards & platforms",
    ],
  },
  {
    id: 3,
    title: "UI/UX Design",
    url: "/services/design",
    image: {
      src: `${fservice3}`,
      srcSet: {
        full: `${fservice3} 992w`,
        small: `${fservice3} 219w`,
        medium: `${fservice3} 747w`,
        large: `${fservice3} 768w`,
      },
      alt: "design services",
    },
    icon: {
      src: designIcon,
      alt: "design services",
    },
    descriptions: [
      "User-centered design solutions",
      "Figma & Adobe Creative Suite",
    ],
  },
  {
    id: 4,
    title: "DevOps Services",
    url: "/services/devops",
    image: {
      src: `${fservice4}`,
      srcSet: {
        full: `${fservice4} 992w`,
        small: `${fservice4} 219w`,
        medium: `${fservice4} 747w`,
        large: `${fservice4} 768w`,
      },
      alt: "devops",
    },
    icon: {
      src: devopsIcon,
      alt: "devops",
    },
    descriptions: [
      "CI/CD pipeline implementation",
      "Docker & Kubernetes expertise",
    ],
  },
  {
    id: 5,
    title: "Enterprise Solutions",
    url: "/services/enterprise",
    image: {
      src: `${fservice5}`,
      srcSet: {
        full: `${fservice5} 992w`,
        small: `${fservice5} 219w`,
        medium: `${fservice5} 747w`,
        large: `${fservice5} 768w`,
      },
      alt: "enterprise solutions",
    },
    icon: {
      src: enterpriseIcon,
      alt: "enterprise solutions",
    },
    descriptions: [
      "Custom ERP & CRM development",
      "Business process automation",
    ],
  },
  {
    id: 6,
    title: "Cloud Solutions",
    url: "/services/cloud",
    image: {
      src: `${fservice6}`,
      srcSet: {
        full: `${fservice6} 992w`,
        small: `${fservice6} 219w`,
        medium: `${fservice6} 747w`,
        large: `${fservice6} 768w`,
      },
      alt: "cloud solutions",
    },
    icon: {
      src: cloudIcon,
      alt: "cloud solutions",
    },
    descriptions: ["AWS cloud infrastructure", "Scalable cloud architecture"],
  },
];
