import React, { useState, useCallback, useMemo } from "react";
import IndustryCard from "../../components/Industries/IndustryCard";
import NavigationArrows from "../../components/Industries/NavigationArrows";
import { industries } from "../../data/industries";

const IndustriesSection = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const visibleSlides = 4;

  const handlePrevClick = useCallback(() => {
    setCurrentSlide(prev => Math.max(0, prev - 3));
  }, []);

  const handleNextClick = useCallback(() => {
    setCurrentSlide(prev => Math.min(industries.length - visibleSlides, prev + 3));
  }, [visibleSlides]);

  const isPrevDisabled = currentSlide === 0;
  const isNextDisabled = currentSlide >= industries.length - visibleSlides;

  const trackStyle = useMemo(() => ({
    opacity: 1,
    width: `${industries.length * 276}px`,
    transform: `translate3d(${-currentSlide * 276}px, 0px, 0px)`,
    transition: 'transform 600ms cubic-bezier(0.4, 0, 0.2, 1)'
  }), [currentSlide]);

  return (
    <section className="section-n section--industries section--en">
      <div className="container-n">
        <div className="section__row pl-big">
          <div>
            <a className="skip-the-section link-n" href="#panel-9-0-0-5">
              <span className="link-n__text">Skip the section</span>
            </a>
            <div className="section__subtitle block-title">Our Expertise</div>
            <h2 className="section__title title-h2">
              Professional Development <br />
              Services & Solutions
            </h2>
          </div>
          <NavigationArrows 
            onPrevClick={handlePrevClick}
            onNextClick={handleNextClick}
            isPrevDisabled={isPrevDisabled}
            isNextDisabled={isNextDisabled}
          />
        </div>
        <div className="section__industries pl-big">
          <div className="industry__list slick-initialized slick-slider">
            <div className="slick-list draggable">
              <div
                className="slick-track"
                style={trackStyle}
              >
                {industries.map((service, index) => (
                  <IndustryCard
                    key={service.id}
                    industry={service}
                    index={index}
                    isActive={index >= currentSlide && index < currentSlide + visibleSlides}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IndustriesSection; 