import mobileDev from "../assets/icons/mobile.svg";
import webDev from "../assets/icons/web.svg";
import database from "../assets/icons/database.svg";
import ux from "../assets/icons/ux.svg";
import ui from "../assets/icons/ui.svg";
import devops from "../assets/icons/devops.svg";
import cloud from "../assets/icons/cloud.svg";
export const serviceCategories = [
  {
    id: "mobile-development",
    title: "Mobile Development",
    description: "Comprehensive mobile application development solutions",
    items: [
      {
        id: "native-development",
        title: "Native Mobile Development",
        description: "Platform-specific mobile app development",
        icon: mobileDev,
        technologies: {
          core: [
            {
              name: "iOS Development",
              features: [
                "Swift Programming",
                "UIKit & SwiftUI",
                "Core Data",
                "iOS Security Features",
                "App Store Deployment"
              ]
            },
            {
              name: "Android Development",
              features: [
                "Kotlin Development",
                "Android SDK",
                "Material Design",
                "Room Database",
                "Google Play Store Publishing"
              ]
            }
          ]
        }
      },
      {
        id: "cross-platform",
        title: "Cross-Platform Development",
        description: "Multi-platform mobile solutions",
        icon: webDev,
        technologies: {
          core: [
            {
              name: "Flutter Development",
              features: [
                "Dart Programming",
                "Widget Development",
                "State Management",
                "Platform Integration",
                "Custom Animations"
              ]
            },
            {
              name: "React Native",
              features: [
                "JavaScript/TypeScript",
                "Native Modules",
                "Performance Optimization",
                "Cross-Platform UI",
                "Native APIs Integration"
              ]
            }
          ]
        }
      }
    ]
  },
  {
    id: "web-development",
    title: "Web Development",
    description: "Modern web application development services",
    items: [
      {
        id: "frontend-development",
        title: "Frontend Development",
        description: "Creating responsive and interactive web interfaces",
        icon: webDev,
        technologies: {
          core: [
            {
              name: "Modern JavaScript",
              features: [
                "React.js",
                "Vue.js",
                "Angular",
                "TypeScript",
                "Progressive Web Apps"
              ]
            },
            {
              name: "UI Frameworks",
              features: [
                "Tailwind CSS",
                "Bootstrap",
                "Material UI",
                "Responsive Design",
                "CSS Architecture"
              ]
            }
          ]
        }
      },
      {
        id: "backend-development",
        title: "Backend Development",
        description: "Scalable server-side solutions",
        icon: database,
        technologies: {
          core: [
            {
              name: "Server Technologies",
              features: [
                "Node.js",
                "Python/Django",
                "Java Spring",
                "PHP/Laravel",
                "GraphQL"
              ]
            },
            {
              name: "Database Solutions",
              features: [
                "PostgreSQL",
                "MongoDB",
                "MySQL",
                "Redis",
                "Database Design"
              ]
            }
          ]
        }
      }
    ]
  },
  {
    id: "uiux-design",
    title: "UI/UX Design",
    description: "User-centered design solutions",
    items: [
      {
        id: "user-experience",
        title: "User Experience Design",
        description: "Creating intuitive and efficient user experiences",
        icon: ux,
        services: [
          {
            name: "UX Research",
            features: [
              "User Research",
              "User Personas",
              "Journey Mapping",
              "Usability Testing",
              "Information Architecture"
            ]
          },
          {
            name: "UX Design",
            features: [
              "Wireframing",
              "Prototyping",
              "Interaction Design",
              "User Flow Design",
              "Accessibility Design"
            ]
          }
        ]
      },
      {
        id: "ui-design",
        title: "User Interface Design",
        description: "Creating visually appealing and functional interfaces",
        icon: ui,
        services: [
          {
            name: "Visual Design",
            features: [
              "Design Systems",
              "Component Libraries",
              "Brand Guidelines",
              "Typography",
              "Color Theory"
            ]
          }
        ]
      }
    ]
  },
  {
    id: "devops-services",
    title: "DevOps Services",
    description: "Streamlined development and operations solutions",
    items: [
      {
        id: "ci-cd",
        title: "CI/CD Implementation",
        description: "Automated deployment and integration solutions",
        icon: devops,
        technologies: {
          core: [
            {
              name: "Pipeline Development",
              features: [
                "Jenkins",
                "GitLab CI",
                "GitHub Actions",
                "Azure DevOps",
                "Automated Testing"
              ]
            }
          ]
        }
      },
      {
        id: "cloud-infrastructure",
        title: "Cloud Infrastructure",
        description: "Cloud-native infrastructure solutions",
        icon: cloud,
        technologies: {
          core: [
            {
              name: "Cloud Platforms",
              features: [
                "AWS",
                "Google Cloud",
                "Azure",
                "Kubernetes",
                "Docker"
              ]
            }
          ]
        }
      }
    ]
  },
  {
    id: "react-fullstack",
    title: "React Full-Stack Development",
    description: "Complete end-to-end React.js development solutions",
    items: [
      {
        id: "frontend-react",
        title: "React Frontend Development",
        description: "Modern frontend development with React ecosystem",
        icon: ui,
        technologies: {
          core: [
            {
              name: "React.js",
              features: [
                "Component-Based Architecture",
                "Virtual DOM",
                "JSX Syntax",
                "Hooks Implementation",
                "Custom Hooks Development"
              ]
            },
            {
              name: "State Management",
              features: [
                "Redux / Redux Toolkit",
                "Context API",
                "Zustand",
                "React Query"
              ]
            },
            {
              name: "Styling Solutions",
              features: [
                "Tailwind CSS",
                "Styled Components",
                "Material-UI (MUI)",
                "CSS Modules"
              ]
            }
          ]
        }
      },
      {
        id: "backend-node",
        title: "Node.js Backend for React",
        description: "Scalable backend solutions optimized for React applications",
        icon: database,
        technologies: {
          core: [
            {
              name: "Node.js + Express",
              features: [
                "RESTful API Development",
                "Middleware Implementation",
                "Authentication & Authorization",
                "API Security"
              ]
            },
            {
              name: "Database Integration",
              features: [
                "MongoDB with Mongoose",
                "PostgreSQL with Prisma",
                "Redis Caching",
                "Database Design"
              ]
            }
          ]
        }
      },
    ]
  },
]; 