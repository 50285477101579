import React, { useState, useEffect, useCallback, useMemo } from "react";
import QuestionSection from "../../components/ProjectAssessment/QuestionSection";
import ServicesSlider from "../../components/ServicesSlider/ServicesSlider";
import { projectAssessmentQuestions } from "../../data/projectAssessmentQuestions";
import { services } from "../../data/services"; // You'll need to create this

const ProjectAssessment = () => {
  const initialState = {
    projectState: null,
    businessNeed: null,
    requirements: null
  };

  const [selectedOptions, setSelectedOptions] = useState(initialState);
  const [expandedQuestion, setExpandedQuestion] = useState(0); // Start with first question open

  useEffect(() => {
    // If trying to close all questions, reopen the last active one
    if (expandedQuestion === -1) {
      // Find the last answered question or default to first question
      const lastAnsweredIndex = Object.values(selectedOptions).reduce((acc, value, index) => {
        return value ? index : acc;
      }, 0);
      setExpandedQuestion(lastAnsweredIndex);
    }
  }, [expandedQuestion, selectedOptions]);

  const handleOptionSelect = useCallback((index, value) => {
    const questionKeys = ['projectState', 'businessNeed', 'requirements'];
    setSelectedOptions(prev => ({
      ...prev,
      [questionKeys[index]]: value
    }));

    // After selection, expand the next unanswered question or keep current if it's the last
    setExpandedQuestion(prev => {
      const nextUnansweredIndex = Object.values(selectedOptions).findIndex((val, i) => i > index && !val);
      return nextUnansweredIndex === -1 ? index : nextUnansweredIndex;
    });
  }, [selectedOptions]);

  const handleToggle = useCallback((index) => {
    setExpandedQuestion(prev => {
      if (prev === index) {
        const lastAnsweredIndex = Object.values(selectedOptions).reduce((acc, value, idx) => {
          return value ? idx : acc;
        }, 0);
        return lastAnsweredIndex;
      }
      return index;
    });
  }, [selectedOptions]);

  const handleReset = useCallback(() => {
    setSelectedOptions(initialState);
    setExpandedQuestion(0);
  }, []);

  const getQuestionOptions = useCallback((index) => {
    const question = projectAssessmentQuestions[index];
    if (index === 0) return question.options;
    if (index === 1 && selectedOptions.projectState) {
      return question.stateOptions[selectedOptions.projectState] || [];
    }
    if (index === 2) return question.options;
    return [];
  }, [selectedOptions.projectState]);

  const getSelectedValue = (index) => {
    return Object.values(selectedOptions)[index];
  };

  return (
    <section className="section-n services-picker section--en pb0">
      <div className="container-n">
        <div className="pl-big">
          <a className="skip-the-section link-n" href="#panel-9-0-0-2">
            <span className="link-n__text">Skip the section</span>
          </a>
          <div className="section__subtitle block-title">
            See how we can help you reach your goals
          </div>
          <div className="section__title title-h2">
            Answer three questions to help us match our <br />
            expertise and software solutions to your needs
          </div>
        </div>
      </div>
      <div className="services-picker__container container-n">
        <div className="services-picker__row">
          <div className="services-picker__block">
            <div className="services-picker__questions">
              {projectAssessmentQuestions.map((question, index) => (
                <QuestionSection 
                  key={index}
                  questionNumber={index + 1}
                  title={question.title}
                  options={getQuestionOptions(index)}
                  isDisabled={index !== 0 && !selectedOptions.projectState}
                  isExpanded={expandedQuestion === index}
                  onSelect={(value) => handleOptionSelect(index, value)}
                  selectedOption={getSelectedValue(index)}
                  onToggle={() => handleToggle(index)}
                />
              ))}
            </div>
          </div>
          <ServicesSlider 
            services={services}
            selectedOptions={selectedOptions}
            onReset={handleReset}
          />
        </div>
      </div>
    </section>
  );
};

export default ProjectAssessment;