import React from "react";

const Footer = () => {
  return (
    <footer
      id="footer"
      class="footer--new"
      itemscope=""
      itemtype="https://schema.org/WPFooter"
    >
      <div class="footer--new__info">
        <div class="container-n">
          <div class="footer--new__row">
            <div class="footer--new__social">
              <ul class="social">
                <li class="social__item">
                  <a
                    class="social__link"
                    href="https://www.facebook.com/VizHacks"
                    target="_blank"
                    aria-label="facebook"
                    rel="nofollow noopener"
                  >
                    <svg
                      width="8"
                      height="15"
                      viewBox="0 0 8 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.38917 14.8396V7.58559H7.3916L7.65697 5.08581H5.38917L5.39257 3.83464C5.39257 3.18266 5.45452 2.83332 6.39095 2.83332H7.64279V0.333252H5.64008C3.23449 0.333252 2.38779 1.54592 2.38779 3.58523V5.08609H0.888306V7.58587H2.38779V14.8396H5.38917Z"
                        fill="white"
                      ></path>
                    </svg>
                  </a>
                </li>
                <li class="social__item">
                  <a
                    class="social__link"
                    href="https://twitter.com/VizHacks"
                    target="_blank"
                    aria-label="twitter"
                    rel="nofollow noopener"
                  >
                    <svg
                      width="16"
                      height="14"
                      viewBox="0 0 16 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.1171 0.333252H14.379L9.43744 5.98111L15.2508 13.6666H10.699L7.13385 9.00539L3.05453 13.6666H0.791282L6.07675 7.62556L0.5 0.333252H5.16735L8.38991 4.59376L12.1171 0.333252ZM11.3232 12.3127H12.5766L4.48632 1.61599H3.14137L11.3232 12.3127Z"
                        fill="white"
                      ></path>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
            <div class="footer--new__submenuWrapper">
              <ul id="menu-footer-submenu" class="footer--new__submenu">
                <li
                  id="menu-item-9762"
                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-9762"
                >
                  <a href="/terms-of-use/">Terms of Use</a>
                </li>
                <li
                  id="menu-item-9763"
                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-9763"
                >
                  <a
                    rel="privacy-policy"
                    href="/privacy-policy/"
                  >
                    Privacy policy
                  </a>
                </li>
              </ul>
            </div>
            <div class="footer--new__copyright">
              © <span itemprop="copyrightYear">2025</span> VizHacks, All
              rights reserved
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
