export const projectAssessmentQuestions = [
  {
    title: "What best describes the current state of your software project?",
    options: [
      {
        value: "idea",
        label: "Idea",
        orderIds: "8798,8799,73636,8802,75532,19596,8800,54956,8801,9894,54090,54304,8803,9804,75425,8806,8808,8807,999999"
      },
      {
        value: "poc",
        label: "PoC",
        orderIds: "8799,73636,8802,75532,19596,8800,54956,8801,9894,54090,54304,8803,9804,8798,75425,8806,8808,8807,999999"
      },
      {
        value: "prototype",
        label: "Prototype",
        orderIds: "73636,8802,8799,75532,19596,8800,54956,8801,9894,54090,54304,8803,9804,8798,75425,8806,8808,8807,999999"
      },
      {
        value: "mvp",
        label: "MVP",
        orderIds: "8802,73636,8807,8799,75532,19596,8800,54956,8801,9894,54090,54304,8803,9804,8798,75425,8806,8808,999999"
      },
      {
        value: "ready_product",
        label: "Ready product",
        orderIds: "73636,8807,8806,8802,8799,75532,19596,8800,54956,8801,9894,54090,54304,8803,9804,8798,75425,8808,999999"
      },
      {
        value: "legacy_system",
        label: "Legacy system",
        orderIds: "8803,9894,8807,73636,8802,8799,75532,19596,8800,54956,8801,54090,54304,9804,8798,75425,8806,8808,999999"
      }
    ]
  },
  {
    title: "What is your primary development focus?",
    stateOptions: {
      idea: [
        {
          value: "mobile_development",
          label: "Mobile Development",
          orderIds: "73636,8799,8801,8802,75532,19596,8800,54956,9894,54090,54304,8803,9804,8798,75425,8806,8808,8807,999999"
        },
        {
          value: "web_development",
          label: "Web Development",
          orderIds: "8799,8798,8801,73636,8802,75532,19596,8800,54956,9894,54090,54304,8803,9804,75425,8806,8808,8807,999999"
        },
        {
          value: "ui_ux_design",
          label: "UI/UX Design",
          orderIds: "8798,8799,8801,73636,8802,75532,19596,8800,54956,9894,54090,54304,8803,9804,75425,8806,8808,8807,999999"
        },
        {
          value: "devops_services",
          label: "DevOps Services",
          orderIds: "8799,8801,73636,8802,75532,19596,8800,54956,9894,54090,54304,8803,9804,8798,75425,8806,8808,8807,999999"
        },
        {
          value: "cloud_solutions",
          label: "Cloud Solutions",
          orderIds: "8801,73636,8802,8799,75532,19596,8800,54956,9894,54090,54304,8803,9804,8798,75425,8806,8808,8807,999999"
        }
      ],
      poc: [
        {
          value: "mobile_development",
          label: "Mobile Development",
          orderIds: "73636,8799,8802,75532,19596,8800,54956,8801,9894,54090,54304,8803,9804,8798,75425,8806,8808,8807,999999"
        },
        {
          value: "web_development",
          label: "Web Development",
          orderIds: "8799,8798,73636,8802,75532,19596,8800,54956,8801,9894,54090,54304,8803,9804,75425,8806,8808,8807,999999"
        },
        {
          value: "ui_ux_design",
          label: "UI/UX Design",
          orderIds: "73636,8799,8802,75532,19596,8800,54956,8801,9894,54090,54304,8803,9804,8798,75425,8806,8808,8807,999999"
        },
        {
          value: "devops_services",
          label: "DevOps Services",
          orderIds: "8799,73636,8802,75532,19596,8800,54956,8801,9894,54090,54304,8803,9804,8798,75425,8806,8808,8807,999999"
        },
        {
          value: "cloud_solutions",
          label: "Cloud Solutions",
          orderIds: "73636,8802,9804,8799,75532,19596,8800,54956,8801,9894,54090,54304,8803,8798,75425,8806,8808,8807,999999"
        }
      ],
      prototype: [
        {
          value: "mobile_development",
          label: "Mobile Development",
          orderIds: "73636,8799,8802,75532,19596,8800,54956,8801,9894,54090,54304,8803,9804,8798,75425,8806,8808,8807,999999"
        },
        {
          value: "web_development",
          label: "Web Development",
          orderIds: "73636,8799,8802,75532,19596,8800,54956,8801,9894,54090,54304,8803,9804,8798,75425,8806,8808,8807,999999"
        },
        {
          value: "ui_ux_design",
          label: "UI/UX Design",
          orderIds: "8799,73636,8802,75532,19596,8800,54956,8801,9894,54090,54304,8803,9804,8798,75425,8806,8808,8807,999999"
        },
        {
          value: "devops_services",
          label: "DevOps Services",
          orderIds: "73636,8802,9804,8799,75532,19596,8800,54956,8801,9894,54090,54304,8803,8798,75425,8806,8808,8807,999999"
        },
        {
          value: "cloud_solutions",
          label: "Cloud Solutions",
          orderIds: "73636,8802,9804,8799,75532,19596,8800,54956,8801,9894,54090,54304,8803,8798,75425,8806,8808,8807,999999"
        }
      ],
      mvp: [
        {
          value: "mobile_development",
          label: "Mobile Development",
          orderIds: "73636,8802,9804,8799,75532,19596,8800,54956,8801,9894,54090,54304,8803,8798,75425,8806,8808,8807,999999"
        },
        {
          value: "web_development",
          label: "Web Development",
          orderIds: "73636,8799,9804,8802,75532,19596,8800,54956,8801,9894,54090,54304,8803,8798,75425,8806,8808,8807,999999"
        },
        {
          value: "ui_ux_design",
          label: "UI/UX Design",
          orderIds: "73636,8799,8802,75532,19596,8800,54956,8801,9894,54090,54304,8803,9804,8798,75425,8806,8808,8807,999999"
        },
        {
          value: "devops_services",
          label: "DevOps Services",
          orderIds: "8807,73636,9804,8802,8799,75532,19596,8800,54956,8801,9894,54090,54304,8803,8798,75425,8806,8808,999999"
        },
        {
          value: "cloud_solutions",
          label: "Cloud Solutions",
          orderIds: "73636,8802,9804,8799,75532,19596,8800,54956,8801,9894,54090,54304,8803,8798,75425,8806,8808,8807,999999"
        }
      ],
      ready_product: [
        {
          value: "mobile_development",
          label: "Mobile Development",
          orderIds: "73636,8802,9804,8799,75532,19596,8800,54956,8801,9894,54090,54304,8803,8798,75425,8806,8808,8807,999999"
        },
        {
          value: "web_development",
          label: "Web Development",
          orderIds: "8807,73636,8802,8799,75532,19596,8800,54956,8801,9894,54090,54304,8803,9804,8798,75425,8806,8808,999999"
        },
        {
          value: "ui_ux_design",
          label: "UI/UX Design",
          orderIds: "8807,9894,9804,73636,8802,8799,75532,19596,8800,54956,8801,54090,54304,8803,8798,75425,8806,8808,999999"
        },
        {
          value: "devops_services",
          label: "DevOps Services",
          orderIds: "73636,8802,9804,8799,75532,19596,8800,54956,8801,9894,54090,54304,8803,8798,75425,8806,8808,8807,999999"
        },
        {
          value: "cloud_solutions",
          label: "Cloud Solutions",
          orderIds: "73636,8802,9894,8799,75532,19596,8800,54956,8801,54090,54304,8803,9804,8798,75425,8806,8808,8807,999999"
        }
      ],
      legacy_system: [
        {
          value: "mobile_development",
          label: "Mobile Development",
          orderIds: "8806,8808,75425,73636,8802,8799,75532,19596,8800,54956,8801,9894,54090,54304,8803,9804,8798,8807,999999"
        },
        {
          value: "web_development",
          label: "Web Development",
          orderIds: "73636,8802,9894,8799,75532,19596,8800,54956,8801,54090,54304,8803,9804,8798,75425,8806,8808,8807,999999"
        },
        {
          value: "ui_ux_design",
          label: "UI/UX Design",
          orderIds: "8807,9894,9804,73636,8802,8799,75532,19596,8800,54956,8801,54090,54304,8803,8798,75425,8806,8808,999999"
        },
        {
          value: "devops_services",
          label: "DevOps Services",
          orderIds: "73636,8808,9804,8802,8799,75532,19596,8800,54956,8801,9894,54090,54304,8803,8798,75425,8806,8807,999999"
        },
        {
          value: "cloud_solutions",
          label: "Cloud Solutions",
          orderIds: "73636,8802,9804,8799,75532,19596,8800,54956,8801,9894,54090,54304,8803,8798,75425,8806,8808,8807,999999"
        }
      ]
    }
  },
  {
    title: "What specific technical requirements do you have?",
    options: [
      {
        value: "cross_platform_mobile",
        label: "Cross-platform mobile applications",
        orderIds: "8800,73636,8802,8799,75532,19596,54956,8801,9894,54090,54304,8803,9804,8798,75425,8806,8808,8807,999999"
      },
      {
        value: "custom_web_applications",
        label: "Custom web applications & portals",
        orderIds: "54956,73636,8802,8799,75532,19596,8800,8801,9894,54090,54304,8803,9804,8798,75425,8806,8808,8807,999999"
      },
      {
        value: "user_centered_design",
        label: "User-centered design solutions",
        orderIds: "19596,73636,8802,8799,75532,8800,54956,8801,9894,54090,54304,8803,9804,8798,75425,8806,8808,8807,999999"
      },
      {
        value: "cicd_implementation",
        label: "CI/CD pipeline implementation",
        orderIds: "54304,73636,8802,8799,75532,19596,8800,54956,8801,9894,54090,8803,9804,8798,75425,8806,8808,8807,999999"
      },
      {
        value: "cloud_infrastructure",
        label: "AWS cloud infrastructure",
        orderIds: "9894,73636,8802,8799,75532,19596,8800,54956,8801,54090,54304,8803,9804,8798,75425,8806,8808,8807,999999"
      },
      {
        value: "docker_kubernetes",
        label: "Docker & Kubernetes expertise",
        orderIds: "54090,73636,8802,8799,75532,19596,8800,54956,8801,9894,54304,8803,9804,8798,75425,8806,8808,8807,999999"
      },
      {
        value: "interactive_dashboards",
        label: "Interactive dashboards & platforms",
        orderIds: "74769,73636,8802,8799,75532,19596,8800,54956,8801,9894,54090,54304,8803,9804,8798,75425,8806,8808,8807,999999"
      }
    ]
  }
]; 