import React, { useState, useEffect } from "react";
import ServiceCard from "./ServiceCard";
import SliderControls from "./SliderControls";

const ServicesSlider = ({ services, selectedOptions, onReset }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [filteredServices, setFilteredServices] = useState(services);
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    if (selectedOptions) {
      const tags = Object.values(selectedOptions).filter(Boolean);
      setSelectedTags(tags);

      // Filter and sort services based on selected options
      const filtered = services.map(service => {
        // Calculate recommendation score based on matching tags
        const score = tags.reduce((acc, tag) => {
          return acc + (service.tags.includes(tag) ? 1 : 0);
        }, 0);

        return {
          ...service,
          isRecommended: score > 0,
          score
        };
      })
      .filter(service => service.score > 0) // Only show relevant services
      .sort((a, b) => b.score - a.score); // Sort by relevance

      setFilteredServices(filtered.length > 0 ? filtered : services);
      setCurrentIndex(0);
    } else {
      setFilteredServices(services);
      setSelectedTags([]);
    }
  }, [selectedOptions, services]);

  const handlePrevClick = () => {
    setCurrentIndex(prev => 
      prev > 0 ? prev - 1 : 0
    );
  };

  const handleNextClick = () => {
    setCurrentIndex(prev => 
      prev < filteredServices.length - 1 ? prev + 1 : prev
    );
  };

  const handleResetFilter = () => {
    setFilteredServices(services);
    setSelectedTags([]);
    setCurrentIndex(0);
    onReset?.(); // Call the parent reset handler
  };

  return (
    <div className="services-picker__slider-wrap">
      <div className="services-picker__slider services-picker__slider--new slick-initialized slick-slider">
        <div className="slick-list draggable">
          <div
            className="slick-track"
            style={{
              opacity: 1,
              width: `${filteredServices.length * 724}px`,
              transform: `translate3d(${-16 - (currentIndex * 724)}px, 0px, 0px)`,
              transition: 'transform 0.3s ease-out'
            }}
          >
            {filteredServices.map((service, index) => (
              <ServiceCard
                key={service.id}
                {...service}
                isActive={index === currentIndex}
                index={index}
              />
            ))}
          </div>
        </div>
      </div>
      <SliderControls
        onPrevClick={handlePrevClick}
        onNextClick={handleNextClick}
        onResetFilter={handleResetFilter}
        selectedTags={selectedTags}
        isPrevDisabled={currentIndex === 0}
        isNextDisabled={currentIndex === filteredServices.length - 1}
      />
    </div>
  );
};

export default ServicesSlider; 