import OverviewImage from "../assets/images/overview.jpg";
import ModernDevelopmentImage from "../assets/images/modern.jpg";
import DevopsImage from "../assets/images/devops.jpg";
import DesignServicesImage from "../assets/images/design.jpg";

import aws from "../assets/technologies/aws.png";
import docker from "../assets/technologies/docker.png";
import express from "../assets/technologies/express.png";
import kubernetes from "../assets/technologies/kubbernetes.png";
import mongodb from "../assets/technologies/mongodb.png";
import react from "../assets/technologies/react.png";
import figma from "../assets/technologies/figma.png";
import photoshop from "../assets/technologies/photoshop.png";
export const cardData = [
  {
    id: 1,
    name: "Company Overview",
    image: {
      src: OverviewImage,
      alt: "company overview",
      srcSet: "",
      sizes: "(max-width: 2704px) 100vw, 2704px",
    },
    title: "Full-Stack Development & Cloud Solutions",
    description:
      "We specialize in modern web and mobile development, cloud infrastructure, and creative design, delivering end-to-end solutions using cutting-edge technologies.",
    info: {
      type: "Core Technologies",
      logos: [
        {
          src: react,
          alt: "React",
        },
        {
          src: docker,
          alt: "Docker",
        },
        {
          src: aws,
          alt: "AWS",
        },
      ],
    },
  },
  {
    id: 2,
    name: "Development Stack",
    image: {
      src: ModernDevelopmentImage,
      alt: "development stack",
      srcSet: "",
      sizes: "(max-width: 2704px) 100vw, 2704px",
    },
    title: "Modern Development Technologies",
    description:
      "Build powerful applications using React.js, React Native, and Express.js backed by MongoDB, ensuring scalable and performant solutions.",
    cta: {
      href: "/services/development",
      text: "View technologies",
    },
    info: {
      type: "Technologies",
      logos: [
        {
          src: react,
          alt: "React Native",
        },
        {
          src: express,
          alt: "Express.js",
        },
        {
          src: mongodb,
          alt: "MongoDB",
        },
      ],
    },
  },
  {
    id: 3,
    name: "Design Services",
    image: {
      src: DesignServicesImage,
      alt: "design services",
      srcSet: "",
      sizes: "(max-width: 2704px) 100vw, 2704px",
    },
    title: "UI/UX Design & Creative Solutions",
    description:
      "Create stunning user interfaces and experiences using industry-leading design tools like Figma and Adobe Photoshop.",
    cta: {
      href: "/services/design",
      text: "View design services",
    },
    info: {
      type: "Design Tools",
      logos: [
        {
          src: figma,
          alt: "Figma",
        },
        {
          src: photoshop,
          alt: "Photoshop",
        },
      ],
    },
  },
  {
    id: 4,
    name: "Cloud & DevOps",
    image: {
      src: DevopsImage,
      alt: "cloud and devops",
      srcSet: "",
      sizes: "(max-width: 2704px) 100vw, 2704px",
    },
    title: "Cloud Infrastructure & DevOps",
    description:
      "Deploy and manage applications using Docker, Kubernetes, and AWS, ensuring scalability, security, and continuous delivery.",
    cta: {
      href: "/services/cloud-devops",
      text: "Explore solutions",
    },
    info: {
      type: "Technologies",
      logos: [
        {
          src: kubernetes,
          alt: "Kubernetes",
        },
        {
          src: docker,
          alt: "Docker",
        },
        {
          src: aws,
          alt: "AWS",
        },
      ],
    },
  },
];
