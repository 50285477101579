import React, { memo } from "react";

const MenuItem = memo(({ title, description, icon, link, subItems }) => (
  <li className={`menu-item menu-item-type-post_type menu-item-object-services ${subItems ? 'submenu menu-item-has-children' : ''}`}>
    <a href={link} className="dropdown-item">
      {icon && (
        <div className="icon-wrap">
          <img src={icon} alt={title} className="icon" />
        </div>
      )}
      <div style={{ textAlign:'left' }} className="menu-title">{title}</div>
      {description && <span className="description">{description}</span>}
    </a>
    {subItems && (
      <div className="dropdown-menu__wrapper">
        <ul className="dropdown-menu">
          {subItems.map((item) => (
            <MenuItem key={item.id} {...item} />
          ))}
        </ul>
      </div>
    )}
  </li>
));

export default MenuItem; 