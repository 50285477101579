import React from "react";
import Overview from "./Overview/Overview";
import ProjectAssessment from "./ProjectAssesment/ProjectAssessment";
import CompanyHighlights from "./CompanyHighlights/CompanyHighlights";
import IndustriesSection from "./Indistries/IndustriesSection";

const Sections = () => {
  return (
    <div id="pg-9-0" className="panel-grid panel-no-style">
      <div id="pgc-9-0-0" className="panel-grid-cell">
        <div
          id="panel-9-0-0-0"
          className="so-panel widget widget_head-home panel-first-child"
          data-index="0"
        >
          <div className="so-widget-head-home so-widget-head-home-style-d75171398898-9">
            <Overview />
          </div>
        </div>
        <div
          id="panel-9-0-0-1"
          className="so-panel widget widget_services-picker-2"
          data-index="1"
        >
          <div className="so-widget-services-picker-2 so-widget-services-picker-2-style-d75171398898-9">
            <ProjectAssessment />
          </div>
        </div>
        {/* <div
          id="panel-9-0-0-3"
          className="so-panel widget widget_company-highlights-section"
          data-index="3"
        >
          <div className="so-widget-company-highlights-section so-widget-company-highlights-section-style-d75171398898-9">
            <CompanyHighlights />
          </div>
        </div> */}
        <div
          id="panel-9-0-0-4"
          className="so-panel widget widget_industries-section"
          data-index="4"
        >
          <div className="so-widget-industries-section so-widget-industries-section-style-d75171398898-9">
            <IndustriesSection />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sections;
