import React, { memo } from 'react';

const CardArrows = memo(({ onPrev, onNext }) => {
  return (
    <div className="card__arrows">
      <svg
        className="card__arrow card__arrow--prev"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="https://www.w3.org/2000/svg"
        onClick={onPrev}
      >
        <rect
          className="card__arrow-circle"
          x="0.5"
          y="0.5"
          width="39"
          height="39"
          rx="19.5"
          stroke="white"
        ></rect>
        <path
          className="card__arrow-icon"
          d="M20 12L12 20M20 28L12 20M12 20L28 20"
          stroke="white"
        ></path>
      </svg>
      <svg
        className="card__arrow card__arrow--next"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="https://www.w3.org/2000/svg"
        onClick={onNext}
      >
        <rect
          className="card__arrow-circle"
          x="0.5"
          y="0.5"
          width="39"
          height="39"
          rx="19.5"
          stroke="white"
        ></rect>
        <path
          className="card__arrow-icon"
          d="M20 28L28 20M20 12L28 20M28 20L12 20"
          stroke="white"
        ></path>
      </svg>
    </div>
  );
});

export default CardArrows; 