import React, { memo } from "react";

const OptionTag = memo(({ value, label, orderIds, onClick, isSelected }) => {
  const handleKeyPress = React.useCallback((e) => {
    if (e.key === 'Enter') {
      onClick?.();
    }
  }, [onClick]);

  return (
    <div
      className={`services-picker__tag description-n ${isSelected ? 'services-picker__tag--active' : ''}`}
      data-value={value}
      data-orders-ids={orderIds}
      tabIndex="0"
      onClick={onClick}
      onKeyPress={handleKeyPress}
    >
      {label}
    </div>
  );
});

OptionTag.displayName = 'OptionTag';

export default OptionTag; 