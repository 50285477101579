import React, { memo, useCallback } from "react";
import OptionTag from "./OptionTag";

const QuestionOptions = memo(({ options, onSelect, selectedValue }) => {
  const createOptionClickHandler = useCallback((value) => () => {
    onSelect?.(value);
  }, [onSelect]);

  if (!Array.isArray(options)) {
    return null;
  }

  return (
    <div className="services-picker__question-list">
      <div className="services-picker__question-info">
        <div className="services-picker__tags">
          {options.map((option, index) => (
            <OptionTag 
              key={`${option.value}-${index}`}
              value={option.value}
              label={option.label}
              orderIds={option.orderIds}
              onClick={createOptionClickHandler(option.value)}
              isSelected={selectedValue === option.value}
            />
          ))}
        </div>
      </div>
    </div>
  );
});

QuestionOptions.displayName = 'QuestionOptions';

export default QuestionOptions; 