import React, { memo, useState } from "react";
import ServiceCategory from "./ServiceCategory";
import { serviceCategories } from "../../../data/menuData";

const ServicesMenu = memo(() => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <li id="menu-item-80344" className="services menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-80344">
      <span className="nav-link" onClick={toggleDropdown}>
        <div className="menu-title">Services</div>
        <div className="dropdown-menu-opener" tabIndex="0"></div>
      </span>
      <div className={`dropdown-menu__wrapper ${isOpen ? 'is-open' : ''}`}>
        <ul className="dropdown-menu">
          {serviceCategories.map((category, index) => (
            <ServiceCategory 
              key={category.id} 
              {...category}
              isActive={activeIndex === index}
              onHover={() => setActiveIndex(index)}
            />
          ))}
        </ul>
      </div>
    </li>
  );
});

export default ServicesMenu; 