import React, { memo } from "react";
import AboutUsImage from "../../../assets/images/menu-about-us-image.svg";
import HowWeWork from "../../../assets/images/menu-how-we-work-image.svg";

const aboutUsItems = [
  {
    id: "about",
    title: "About us",
    description: "Meet VizHacks, your comprehensive development partner specializing in cloud DevOps, mobile & web development, ERP solutions and UX/UI design.",
    link: "/about-us",
    image: AboutUsImage
  },
  {
    id: "services", 
    title: "Our Services",
    description: "Discover our full range of services including cloud solutions, mobile apps, web development, ERP systems and creative design.",
    link: "/services",
    image: HowWeWork
  }
];

const AboutUsMenu = memo(() => (
  <li className="full-width about-us menu-item menu-item-has-children">
    <span className="nav-link">
      <div className="menu-title">About us</div>
      <div className="dropdown-menu-opener" tabIndex="0"></div>
    </span>
    <div className="dropdown-menu__wrapper">
      <ul className="dropdown-menu">
        {aboutUsItems.map(({ id, title, description, link, image }) => (
          <li key={id} className="big-size menu-item">
            <a href={link} className="dropdown-item">
              <div className="menu-title">{title}</div>
              <span className="description">{description}</span>
              <div className="image-wrap">
                <img
                  width="272"
                  height="168"
                  src={image}
                  alt={title.toLowerCase()}
                  className="image"
                />
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  </li>
));

export default AboutUsMenu; 