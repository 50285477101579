import React, { memo } from 'react';

const NavigationArrows = memo(({ onPrevClick, onNextClick, isPrevDisabled, isNextDisabled }) => {
  return (
    <div className="section__arrows">
      <button
        className={`section__arrow section__arrow--prev slick-arrow ${isPrevDisabled ? 'slick-disabled' : ''}`}
        aria-disabled={isPrevDisabled}
        onClick={onPrevClick}
        disabled={isPrevDisabled}
        type="button"
      >
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="https://www.w3.org/2000/svg"
        >
          <rect
            className="section__arrow-circle"
            x="0.5"
            y="0.5"
            width="39"
            height="39"
            rx="19.5"
            stroke="#07393C"
          />
          <path
            className="section__arrow-icon"
            d="M20 12L12 20M20 28L12 20M12 20L28 20"
            stroke="#07393C"
          />
        </svg>
      </button>
      <button
        className={`section__arrow section__arrow--next slick-arrow ${isNextDisabled ? 'slick-disabled' : ''}`}
        aria-disabled={isNextDisabled}
        onClick={onNextClick}
        disabled={isNextDisabled}
        type="button"
      >
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="https://www.w3.org/2000/svg"
        >
          <rect
            className="section__arrow-circle"
            x="0.5"
            y="0.5"
            width="39"
            height="39"
            rx="19.5"
            stroke="#07393C"
          />
          <path
            className="section__arrow-icon"
            d="M20 28L28 20M20 12L28 20M28 20L12 20"
            stroke="#07393C"
          />
        </svg>
      </button>
    </div>
  );
});

NavigationArrows.displayName = 'NavigationArrows';

export default NavigationArrows; 