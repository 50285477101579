import React, { memo } from "react";

const MenuToggle = memo(() => {
  const handleMenuToggle = () => {
    document.body.classList.toggle('mobile-menu-active');
  };

  return (
    <div onClick={handleMenuToggle} className="hide-show-menu hide_show_menu_btn" tabIndex="0">
      <div className="hide-show-menu-icon"></div>
    </div>
  );
});

export default MenuToggle; 