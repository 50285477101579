import React, { memo } from "react";

const Logo = memo(() => (
  <a href="/" className="header--new__logo" aria-label="Logo">
    <span className="header--new__logo-img-wrap">
      <svg
        class="header--new__logo-img"
        xmlns="http://www.w3.org/2000/svg"
        version="1.2"
        viewBox="0 0 164 29"
        width="164"
        height="29"
      >
        <path
          fill-rule="evenodd"
          class="s0"
          d="m6.9 4.1l-2.1-4.1h-5.8l2.7 5.2 2.3 4.6z"
        />
        <path fill-rule="evenodd" class="s0" d="m21.6 10.8h-11.4v-4.7h11.4z" />
        <path
          fill-rule="evenodd"
          class="s0"
          d="m13.8 17.6l-2.3-4.5-2.9 5.6 5.2 10.3 2.9-5.7z"
        />
        <path
          fill-rule="evenodd"
          class="s0"
          d="m22.8 0l-2.7 5.2-5.5 10.8 2.9 5.7 8.4-16.5 2.7-5.2z"
        />
        <path
          fill-rule="evenodd"
          class="s0"
          d="m10.6 0l-2.6 5.2-3.2 6.2 2.7 5.3 0.2 0.4 6.1-11.9 2.6-5.2z"
        />
        <g>
          <path
            class="s0"
            d="m39.9 23.4l-7.9-20.2h4l6.1 16.3 6.1-16.3h4l-7.9 20.2z"
          />
          <path
            class="s0"
            d="m53.9 5c0-1.1 0.9-2 2-2 1.1 0 1.9 0.9 1.9 2 0 1.1-0.8 1.9-1.9 1.9-1.1 0-2-0.8-2-1.9zm0.4 18.4v-14.7h3.2v14.7z"
          />
          <path
            class="s0"
            d="m60.9 23.4v-2.4l7.2-9.5h-7.2v-2.8h11.5v2.4l-7.3 9.5h7.4v2.8z"
          />
          <path
            class="s0"
            d="m90 23.4v-8.9h-10.5v8.9h-3.5v-20.2h3.5v8.2h10.5v-8.2h3.5v20.2z"
          />
          <path
            class="s0"
            d="m108 23.4v-2c-1.1 1.5-2.8 2.4-4.7 2.4-3.7 0-6.5-2.9-6.5-7.7 0-4.8 2.8-7.7 6.5-7.7 1.8 0 3.5 0.8 4.7 2.4v-2.1h3.1v14.7zm0-4.4v-5.8c-0.7-1.1-2.2-2-3.7-2-2.5 0-4.1 2-4.1 4.9 0 2.8 1.6 4.9 4.1 4.9 1.5 0 3-0.9 3.7-2z"
          />
          <path
            class="s0"
            d="m114.4 16.1c0-4.5 3.1-7.7 7.5-7.7 2.8 0 4.5 1.2 5.5 2.6l-2 1.9q-1.3-1.7-3.4-1.7c-2.6 0-4.4 2-4.4 4.9 0 2.8 1.8 4.9 4.4 4.9q2.1-0.1 3.4-1.8l2 2c-1 1.3-2.7 2.6-5.5 2.6-4.4 0-7.5-3.3-7.5-7.7z"
          />
          <path
            class="s0"
            d="m139.9 23.4l-4.4-6.1-2.1 2.2v3.9h-3.1v-20.2h3.1v12.6l6.4-7.1h4l-6.1 6.7 6.2 8z"
          />
          <path
            class="s0"
            d="m144.9 21.5l1.5-2.3c1 1.1 3 2 4.9 2 1.9 0 2.9-0.8 2.9-1.9 0-2.9-8.8-0.8-8.8-6.5 0-2.4 2-4.4 5.7-4.4 2.5 0 4.3 0.9 5.6 2l-1.3 2.3c-0.9-1-2.5-1.8-4.3-1.8-1.7 0-2.7 0.8-2.7 1.8 0 2.6 8.8 0.7 8.8 6.5 0 2.6-2.1 4.6-6 4.6-2.6 0-4.8-0.8-6.3-2.3z"
          />
        </g>
      </svg>
    </span>
  </a>
));

export default Logo;
