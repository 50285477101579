import React from 'react';

const SliderControls = ({ 
  onPrevClick, 
  onNextClick, 
  onResetFilter, 
  selectedTags, 
  isPrevDisabled 
}) => {
  return (
    <div className="services-picker__controls">
      <div className="services-picker__arrows">
        <svg 
          className={`services-picker__arrow services-picker__arrow--prev slick-arrow ${isPrevDisabled ? 'slick-disabled' : ''}`}
          width="40" 
          height="40" 
          viewBox="0 0 40 40" 
          fill="none" 
          xmlns="http://www.w3.org/2000/svg"
          onClick={!isPrevDisabled ? onPrevClick : undefined}
          aria-disabled={isPrevDisabled}
        >
          <rect 
            className="services-picker__arrow-circle" 
            x="0.5" 
            y="0.5" 
            width="39" 
            height="39" 
            rx="19.5" 
            stroke="#07393C"
          />
          <path 
            className="services-picker__arrow-icon" 
            d="M20 12L12 20M20 28L12 20M12 20L28 20" 
            stroke="#07393C"
          />
        </svg>
        <svg 
          className="services-picker__arrow services-picker__arrow--next slick-arrow"
          width="40" 
          height="40" 
          viewBox="0 0 40 40" 
          fill="none" 
          xmlns="http://www.w3.org/2000/svg"
          onClick={onNextClick}
          aria-disabled="false"
        >
          <rect 
            className="services-picker__arrow-circle" 
            x="0.5" 
            y="0.5" 
            width="39" 
            height="39" 
            rx="19.5" 
            stroke="#07393C"
          />
          <path 
            className="services-picker__arrow-icon" 
            d="M20 28L28 20M20 12L28 20M28 20L12 20" 
            stroke="#07393C"
          />
        </svg>
      </div>
      {selectedTags.length > 0 && (
        <div className="services-picker__clear services-picker__clear--show">
          <div className="services-picker__clear-tags">
            {selectedTags.map((tag, index) => (
              <div key={index} className="services-picker__tag description-n">
                {tag}
              </div>
            ))}
          </div>
          <div 
            className="services-picker__clear-btn description-n" 
            tabIndex="0"
            onClick={onResetFilter}
          >
            Reset filter
            <svg 
              className="services-picker__clear-icon" 
              xmlns="http://www.w3.org/2000/svg" 
              width="16" 
              height="16" 
              viewBox="0 0 16 16" 
              fill="none"
            >
              <path 
                className="services-picker__clear-icon-path" 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M1.76772 1.76772C2.12469 1.41076 2.70344 1.41076 3.0604 1.76772L8 6.70732L12.9396 1.76772C13.2966 1.41076 13.8753 1.41076 14.2323 1.76772C14.5892 2.12469 14.5892 2.70344 14.2323 3.0604L9.29268 8L14.2323 12.9396C14.5892 13.2966 14.5892 13.8753 14.2323 14.2323C13.8753 14.5892 13.2966 14.5892 12.9396 14.2323L8 9.29268L3.0604 14.2323C2.70344 14.5892 2.12469 14.5892 1.76772 14.2323C1.41076 13.8753 1.41076 13.2966 1.76772 12.9396L6.70732 8L1.76772 3.0604C1.41076 2.70344 1.41076 2.12469 1.76772 1.76772Z"
              />
            </svg>
          </div>
        </div>
      )}
    </div>
  );
};

export default SliderControls; 