import React, { useState, useRef, useEffect, memo, useCallback } from "react";
import QuestionTitle from "./QuestionTitle";
import QuestionOptions from "./QuestionOptions";

const QuestionSection = memo(({ 
  questionNumber, 
  title, 
  options, 
  isDisabled,
  isExpanded,
  onSelect,
  selectedOption,
  onToggle 
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [options, isExpanded]);

  const handleMouseEnter = useCallback(() => {
    if (!isDisabled) {
      setIsHovered(true);
    }
  }, [isDisabled]);

  const handleMouseLeave = useCallback(() => {
    if (!isDisabled) {
      setIsHovered(false);
    }
  }, [isDisabled]);

  const handleSelect = useCallback((value) => {
    onSelect(value);
  }, [onSelect]);

  return (
    <div 
      className={`services-picker__question services-picker__question--${questionNumber} 
        ${isDisabled ? 'services-picker__question--disable' : ''} 
        ${isExpanded ? 'services-picker__question--expanded' : 'services-picker__question--closed'}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <QuestionTitle 
        number={questionNumber} 
        title={title}
        isExpanded={isExpanded}
        onToggle={!isDisabled ? onToggle : undefined}
        selectedOption={selectedOption}
        isDisabled={isDisabled}
      />
      
      <div 
        ref={contentRef}
        style={{ 
          height: isExpanded ? contentHeight : 0,
          overflow: 'hidden',
          transition: 'height 0.3s ease-out'
        }}
      >
        <QuestionOptions 
          options={options} 
          onSelect={handleSelect}
          selectedValue={selectedOption}
        />
      </div>
      
      {isDisabled && (
        <div 
          className="services-picker__question-hint"
          style={{
            display: isHovered ? 'block' : 'none',
            top: '10px',
            left: questionNumber === 2 ? '49px' : '139px'
          }}
        >
          Please answer the previous question to move forward
        </div>
      )}
    </div>
  );
});

QuestionSection.displayName = 'QuestionSection';

export default QuestionSection; 