import React, { useState } from "react";
import FormInput from "./FormInput";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    country: "",
    company: "",
    message: "",
    newsletter: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  return (
    <div className="form" data-origin="bottom" data-distance="50px">
      <div className="gf_browser_chrome gform_wrapper gform_legacy_markup_wrapper gform-theme--no-framework">
        <form
          method="post"
          encType="multipart/form-data"
          id="gform_1"
          action="/#gf_1"
          noValidate=""
        >
          <div className="gform-body gform_body">
            <div className="formrowlist">
              <FormInput
                id="input_1_1"
                name="fullName"
                type="text"
                label="Full name"
                required={true}
                maxLength={80}
                value={formData.fullName}
                onChange={handleChange}
                tooltip="We need your name to know how to address you"
              />

              <FormInput
                id="input_1_3"
                name="email"
                type="email"
                label="Email"
                required={true}
                value={formData.email}
                onChange={handleChange}
                tooltip="We need your email to respond to your request"
              />

              <FormInput
                id="input_1_4"
                name="phone"
                type="tel"
                label="Phone number"
                required={true}
                value={formData.phone}
                onChange={handleChange}
                tooltip="We need your phone number to reach you with response to your request"
              />

              <FormInput
                id="input_1_5"
                name="country"
                type="text"
                label="Country"
                required={true}
                maxLength={80}
                value={formData.country}
                onChange={handleChange}
                tooltip="We need your country of business to know from what office to contact you"
              />

              <FormInput
                id="input_1_2"
                name="company"
                type="text"
                label="Company"
                required={true}
                maxLength={80}
                value={formData.company}
                onChange={handleChange}
                tooltip="We need your company name to know your background and how we can use our experience to help you"
              />

              <FormInput
                id="input_1_17"
                name="message"
                type="textarea"
                label="Message"
                required={true}
                value={formData.message}
                onChange={handleChange}
              />

              <div className="formrow formrow__attach">
                <div className="atachfile">
                  <ul>
                    <li></li>
                    <li className="gfield gfield--type-fileupload">
                      <label className="gfield_label gform-field-label">
                        Attach file
                      </label>
                      <div className="ginput_container ginput_container_fileupload">
                        <input type="file" className="medium" />
                        <span className="gfield_description gform_fileupload_rules">
                          Accepted file types: jpg, gif, png, pdf, doc, docx,
                          xls, xlsx, ppt, pptx, Max. file size: 10 MB.
                        </span>
                      </div>
                    </li>
                  </ul>
                  <div className="atachfile_cont">
                    <p className="atachfile_link">Add an attachment</p>
                    <span className="atachfile_text">
                      (jpg, gif, png, pdf, doc, docx, xls, xlsx, ppt, pptx, PNG)
                    </span>
                  </div>
                </div>
              </div>

              <div className="formrow--checkbox">
                <ul>
                  <li></li>
                  <li className="gfield gfield--type-checkbox">
                    <div className="ginput_container ginput_container_checkbox">
                      <ul className="gfield_checkbox">
                        <li className="gchoice">
                          <input
                            type="checkbox"
                            name="newsletter"
                            checked={formData.newsletter}
                            onChange={handleChange}
                            className="gfield-choice-input"
                          />
                          <label className="gform-field-label gform-field-label--type-inline">
                            I want to receive news and updates once in a while
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div
            className="form__info"
            style={{ marginTop: "20px", textAlign: "left" }}
          >
            We will add your info to our CRM for contacting you regarding your
            request. For more info please consult our{" "}
            <a href="/privacy-policy/">privacy policy</a>
          </div>

          <div style={{ width: "100%", textAlign: "center" }} className="gform-footer gform_footer top_label">
            <input
              type="submit"
              className="gform_button button"
              value="Contact us"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
