import React, { memo } from "react";

const QuestionTitle = memo(({ number, title, isExpanded, onToggle, selectedOption, isDisabled }) => {
  return (
    <div 
      className={`services-picker__question-title title-h5 ${isDisabled ? 'services-picker__question-title--disabled' : ''}`}
      onClick={onToggle}
      style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
    >
      <span className="services-picker__question-title-number">
        {number}.
      </span>
      <span className="services-picker__question-title-text">
        {title}
        {selectedOption && (
          <span className="services-picker__selected-option">
            : {selectedOption}
          </span>
        )}
      </span>
      <svg 
        className={`services-picker__question-title-icon ${isExpanded ? 'services-picker__question-title-icon--minus' : ''}`}
        width="40" 
        height="40" 
        viewBox="0 0 40 40" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
        style={{ opacity: isDisabled ? 0.5 : 1 }}
      >
        <path 
          className="services-picker__question-title-icon-plus" 
          d="M20 28L20 20L20 12" 
          stroke="#07393C"
          style={{ opacity: isExpanded ? 0 : 1 }}
        />
        <path 
          d="M12 20L20 20L28 20" 
          stroke="#07393C"
        />
      </svg>
    </div>
  );
});

QuestionTitle.displayName = 'QuestionTitle';

export default QuestionTitle; 