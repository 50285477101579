import React, { memo } from "react";
import Logo from "./components/Logo";
import MainNav from "./components/MainNav";
import ContactButton from "./components/ContactButton";
import MenuToggle from "./components/MenuToggle";

const Navbar = memo(() => {
  return (
    <header className="header--new">
      <div className="container-n">
        <div className="header--new__row">
          <Logo />
          <div className="header--new__info">
            <MainNav />
            <ContactButton desktop />
          </div>
          <ContactButton mobile />
          <MenuToggle />
        </div>
      </div>
    </header>
  );
});

export default Navbar;
