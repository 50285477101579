import React, { useState, useEffect, useCallback, memo } from "react";
import VHCard from "../../components/VHCard/VHCard";
import { cardData } from "../../data/cardData";

const Overview = () => {
  const [activeCardIndex, setActiveCardIndex] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);

  useEffect(() => {
    let interval;
    if (autoPlay) {
      interval = setInterval(() => {
        setActiveCardIndex((prevIndex) =>
          prevIndex === cardData.length - 1 ? 0 : prevIndex + 1
        );
      }, 18000); // 18 seconds
    }
    return () => clearInterval(interval);
  }, [autoPlay, activeCardIndex]);

  const handlePrevCard = useCallback(
    (e) => {
      e.preventDefault(); // Prevent any default behavior
      e.stopPropagation(); // Stop event bubbling
      // setAutoPlay(false);
      const newIndex =
        activeCardIndex === 0 ? cardData.length - 1 : activeCardIndex - 1;
      setActiveCardIndex(newIndex);
    },
    [activeCardIndex]
  );

  const handleNextCard = useCallback(
    (e) => {
      e.preventDefault(); // Prevent any default behavior
      e.stopPropagation(); // Stop event bubbling
      // setAutoPlay(false);
      const newIndex =
        activeCardIndex === cardData.length - 1 ? 0 : activeCardIndex + 1;
      setActiveCardIndex(newIndex);
    },
    [activeCardIndex]
  );

  const handleCardSelect = useCallback((index) => {
    // setAutoPlay(false);
    setActiveCardIndex(index);
  }, []);

  return (
    <section className="section--head-home section--en">
      <div className="container-n">
        <div className="section__cards-holder">
          <div
            className={`section__cards show`}
            data-id="1"
            data-count="0"
            data-region="default"
            style={{ display: "flex" }}
          >
            {cardData.map((card, index) => (
              <VHCard
                key={card.id}
                data={card}
                index={index}
                isActive={index === activeCardIndex}
                onCardSelect={handleCardSelect}
                handlePrevCard={handlePrevCard}
                handleNextCard={handleNextCard}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default memo(Overview);
