import React, { memo, useCallback } from "react";
import PlusIcon from "../../icons/PlusIcon";
import CardArrows from "./CardArrows";

const VHCard = memo(
  ({ data, isActive, index, onCardSelect, handlePrevCard, handleNextCard }) => {
    const handlePlusClick = useCallback(() => {
      if (index !== -1) {
        onCardSelect(index);
      }
    }, [index, onCardSelect]);

    return (
      <div
        onClick={handlePlusClick}
        className={`card ${isActive ? "active" : ""}`}
        data-id={data.id}
      >
        <img
          fetchpriority="high"
          decoding="async"
          width="2704"
          height="1600"
          src={data.image.src}
          className="card__image img-fluid"
          alt={data.image.alt}
          srcSet={data.image.srcSet}
          sizes={data.image.sizes}
        />
        <div className="card__mask"></div>

        {/* Card Icon */}
        <div
          tabIndex="0"
          data-id={data.id}
          className="card__icon-wrapper"
          role="button"
          aria-label={`View ${data.name} details`}
          style={{ cursor: "pointer", zIndex: 10 }}
        >
          <PlusIcon />
        </div>
        <div className="card__name">{data.name}</div>

        <div className="card__text" style={{ paddingBottom: "30px" }}>
          <h2 className="title-h1 pl-mid">{data.title}</h2>
          <div className="card__block pl-big">
            <div className="description-n">{data.description}</div>
            {data.cta && (
              <>
                <a
                  href={data.cta.href}
                  className="card__btn btn-n btn-n--white btn-n--small"
                  tabIndex="-1"
                >
                  <span className="btn-n__text">{data.cta.text}</span>
                </a>
                <a
                  href={data.cta.href}
                  className="card__btn-link link-n link-n--white"
                  tabIndex="-1"
                >
                  <span className="link-n__text">{data.cta.text}</span>
                </a>
              </>
            )}
          </div>

          <div className="card__info pl-big">
            <div className="card__progress">
              <div className="card__progress-line"></div>
            </div>
            <div className="card__subtitle title-h5">{data.info.type}</div>

            {data.info.logos && (
              <div className="card__logos">
                {data.info.logos.map((logo, index) => (
                  <img
                    key={index}
                    decoding="async"
                    src={logo.src}
                    className="card__logo img-fluid"
                    alt={logo.alt}
                    height="0"
                    width="0"
                  />
                ))}
              </div>
            )}

            {data.info.links && (
              <div className="card__links">
                {data.info.links.map((link, index) => (
                  <div key={index}>
                    <a
                      href={link.href}
                      className="card__link title-h5"
                      tabIndex="-1"
                    >
                      {link.text}{" "}
                    </a>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <CardArrows onPrev={handlePrevCard} onNext={handleNextCard} />
        <div class="card__arrows--mobile">
          <svg
            onClick={handlePrevCard}
            class="card__arrow card__arrow--prev slick-disabled"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="https://www.w3.org/2000/svg"
          >
            <rect
              class="card__arrow-circle"
              x="0.5"
              y="0.5"
              width="39"
              height="39"
              rx="19.5"
              stroke="white"
            ></rect>
            <path
              class="card__arrow-icon"
              d="M20 12L12 20M20 28L12 20M12 20L28 20"
              stroke="white"
            ></path>
          </svg>
          <svg
            onClick={handleNextCard}
            class="card__arrow card__arrow--next slick-disabled"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="https://www.w3.org/2000/svg"
          >
            <rect
              class="card__arrow-circle"
              x="0.5"
              y="0.5"
              width="39"
              height="39"
              rx="19.5"
              stroke="white"
            ></rect>
            <path
              class="card__arrow-icon"
              d="M20 28L28 20M20 12L28 20M28 20L12 20"
              stroke="white"
            ></path>
          </svg>
        </div>
      </div>
    );
  }
);

export default VHCard;
