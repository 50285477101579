import React from "react";

const PlusIcon = () => {
  return (
    <svg
      className="services-picker__question-title-icon"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="services-picker__question-title-icon-plus"
        d="M20 28L20 20L20 12"
        stroke="#07393C"
      />
      <path
        d="M12 20L20 20L28 20"
        stroke="#07393C"
      />
    </svg>
  );
};

export default PlusIcon;
