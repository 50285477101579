import React from "react";
import ContactForm from '../../components/forms/ContactForm';

const Discuss = () => {
  return (
    <section
      data-wpr-lazyrender="1"
      class="section-n section--black contact-us-new"
      id="contact-us"
    >
      <div class="container-n">
        <div class="pl-big section__row">
          <a
            class="skip-the-section skip-the-section--custom link-n link-n--white"
            href="#footer"
          >
            <span class="link-n__text">Skip the section</span>
          </a>
          <div class="col-form">
            <div
              class="section__title title-h2"
              style={{ textAlign: "left" }}
              data-origin="bottom"
              data-distance="25px"
            >
              Get in touch to discuss your software vision with industry experts{" "}
            </div>
            <ContactForm />
          </div>

          {/* <div class="testimonial-new__info">
            <div class="testimonial-new__timeline">
              <div
                id="testimonial-new__timeline-line"
                class="testimonial-new__timeline-line animate"
              ></div>
            </div>
            <div class="testimonial-new__arrows">
              <svg
                class="testimonial-new__arrow testimonial-new__arrow--prev slick-arrow"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="https://www.w3.org/2000/svg"
              >
                <rect
                  class="testimonial-new__arrow-circle"
                  x="0.5"
                  y="0.5"
                  width="39"
                  height="39"
                  rx="19.5"
                  stroke="#07393C"
                ></rect>
                <path
                  class="testimonial-new__arrow-icon"
                  d="M20 12L12 20M20 28L12 20M12 20L28 20"
                  stroke="#07393C"
                ></path>
              </svg>
              <svg
                class="testimonial-new__arrow testimonial-new__arrow--next slick-arrow"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="https://www.w3.org/2000/svg"
              >
                <rect
                  class="testimonial-new__arrow-circle"
                  x="0.5"
                  y="0.5"
                  width="39"
                  height="39"
                  rx="19.5"
                  stroke="#07393C"
                ></rect>
                <path
                  class="testimonial-new__arrow-icon"
                  d="M20 28L28 20M20 12L28 20M28 20L12 20"
                  stroke="#07393C"
                ></path>
              </svg>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Discuss;
