import React, { memo } from 'react';

const IndustryCard = memo(({ industry, index, isActive }) => {
  return (
    <a
      href={industry.url}
      className={`industry__item slick-slide ${isActive ? "slick-active" : ""}`}
      tabIndex={isActive ? "0" : "-1"}
      style={{ width: "276px" }}
      data-slick-index={index}
      aria-hidden={!isActive}
    >
      <div>
        <img
          decoding="async"
          loading="lazy"
          width="992"
          height="1360"
          src={industry.image.src}
          className="industry__image"
          alt={industry.image.alt}
        />
        <div className="industry__mask" />
      </div>
      <div className="industry__content">
        <div className="industry__icon-wrap">
          <img
            decoding="async"
            loading="lazy"
            src={industry.icon.src}
            className="industry__icon"
            alt={industry.icon.alt}
            height="40"
            width="40"
          />
        </div>
        <h3
          className="industry__title title-h4"
          style={{ textAlign: "left" }}
        >
          {industry.title}
        </h3>
        {industry.descriptions.map((desc, i) => (
          <div
            key={`${industry.id}-desc-${i}`}
            className="industry__text description-n"
            style={{ textAlign: "left" }}
          >
            {desc}
          </div>
        ))}
        <span className="industry__url link-n link-n--white">
          <span className="link-n__text">View service</span>
        </span>
      </div>
    </a>
  );
});

IndustryCard.displayName = 'IndustryCard';

export default IndustryCard;
