import React, { useState } from 'react';

const FormInput = ({
  id,
  name,
  type = 'text',
  label,
  required = false,
  maxLength,
  value,
  onChange,
  tooltip
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  const renderInput = () => {
    if (type === 'textarea') {
      return (
        <textarea
          name={name}
          id={id}
          className="textarea medium"
          required={required}
          aria-invalid="false"
          rows="10"
          cols="50"
          value={value}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      );
    }

    return (
      <>
        <input
          name={name}
          id={id}
          type={type}
          value={value}
          className="medium"
          maxLength={maxLength}
          aria-required={required}
          aria-invalid="false"
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        {maxLength && (
          <div className="charleft ginput_counter gfield_description" aria-live="polite">
            {value.length} of {maxLength} max characters
          </div>
        )}
      </>
    );
  };

  return (
    <div className={`formrow formrow__${name} ${isFocused ? 'formrow__infocus' : ''}`}>
      <ul>
        <li></li>
        <li
          id={`field_1_${id}`}
          className={`gfield gfield--type-${type} gfield--input-type-${type} ${name} ${required ? 'gfield_contains_required' : ''} field_sublabel_below gfield--no-description field_description_below field_validation_below gfield_visibility_visible`}
          data-js-reload={`field_1_${id}`}
        >
          <label className="gfield_label gform-field-label" htmlFor={id}>
            {label}
            {required && (
              <span className="gfield_required">
                <span className="gfield_required gfield_required_asterisk">*</span>
              </span>
            )}
          </label>
          <div className={`ginput_container ginput_container_${type}`}>
            {renderInput()}
          </div>
        </li>
        {tooltip && (
          <li
            className="gfield gfield--type-html gfield--input-type-html gfield_html gfield_no_follows_desc field_sublabel_below gfield--no-description field_description_below field_validation_below gfield_visibility_visible"
          >
            <div className="form__tooltip">{tooltip}</div>
          </li>
        )}
      </ul>
    </div>
  );
};

export default FormInput; 