import React, { memo } from "react";
import MenuItem from "./MenuItem";

const ServiceCategory = memo(({ title, description, items, isActive, onHover }) => {
  return (
    <li 
      className={`menu-title block-group menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children ${isActive ? 'active' : ''}`}
      onMouseEnter={onHover}
    >
      <span className="dropdown-item" tabIndex="0">
        <div style={{ textAlign:'left' }} className="menu-title">{title}</div>
        <span className="description">{description}</span>
      </span>
      <div className="dropdown-menu__wrapper">
        <ul className="dropdown-menu">
          {items.map((item) => (
            <MenuItem key={item.id} {...item} />
          ))}
        </ul>
      </div>
    </li>
  );
});

export default ServiceCategory; 