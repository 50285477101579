import "./App.css";
import Footer from "./layouts/Footer";
import Navbar from "./layouts/Navbar";
import Contact from "./screens/Contact/Contact";
import Sections from "./screens/Sections";
function App() {
  return (
    <div className="App">
      <Navbar />
      <div class="header--new__mask"></div>
      <Sections />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
