import React from "react";

const ServiceCard = ({ 
  id, 
  href, 
  isActive, 
  index, 
  category, 
  isRecommended, 
  title, 
  description, 
  imageSrc, 
  imageSrcSet 
}) => {
  return (
    <a
      href={href}
      id={id}
      className={`services-picker__item slick-slide ${isActive ? 'slick-current slick-active' : ''}`}
      data-slick-index={index}
      aria-hidden={!isActive}
      tabIndex={isActive ? 0 : -1}
    >
      <div className="services-picker__item-category description-n">
        {category}
      </div>
      {isRecommended && (
        <div className="services-picker__item-category--recomended description-n">
          Recommended
        </div>
      )}
      <div className="services-picker__item-title title-h4">
        {title}
      </div>
      <div className="services-picker__item-description description-n">
        {description}
      </div>
      <div className="services-picker__item-link">
        <div className="services-picker__item-line" />
        {/* <img
          decoding="async"
          width="724"
          height="240"
          src={imageSrc}
          className="services-picker__item-image img-fluid"
          alt={title}
          srcSet={imageSrcSet}
          sizes="(max-width: 724px) 100vw, 724px"
        /> */}
        <div className="services-picker__item-mask" />
        <div className="services-picker__item-view description-n">
          View service
        </div>
        <svg
          className="services-picker__item-icon"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            className="services-picker__item-icon-circle"
            x="0.5"
            y="0.5"
            width="39"
            height="39"
            rx="19.5"
            stroke="#C7C7D6"
          />
          <path
            className="services-picker__item-icon-arrow"
            d="M20 28L28 20M20 12L28 20M28 20L12 20"
            stroke="#C7C7D6"
          />
        </svg>
      </div>
    </a>
  );
};

export default ServiceCard; 