import React, { memo } from "react";

const ContactButton = memo(({ mobile }) => {
  const className = mobile 
    ? "header--new__btn--mobile btn-n btn-n--black"
    : "header--new__btn--desktop btn-n btn-n--black";

  return (
    <a className={className} href="/contact-us">
      <span className="btn-n__text">Contact us</span>
    </a>
  );
});

export default ContactButton; 