import React, { memo } from "react";
import ServicesMenu from "./nav/ServicesMenu";
import AboutUsMenu from "./nav/AboutUsMenu";

const MainNav = memo(() => (
  <nav className="header__menu--newWrap">
    <ul id="menu-header-menu-redesigned" className="header__menu--new">
      <ServicesMenu />
      <AboutUsMenu />
    </ul>
  </nav>
));

export default MainNav; 